document.addEventListener("DOMContentLoaded", () => {
	this.initNavTrack();
	this.initMenu();
});

function initNavTrack() {
	const sections = document.querySelectorAll('section');
	const navItems = document.querySelectorAll(".navbar__links > a");

	window.onscroll = () => {
		var current = "";

		sections.forEach((section) => {
			const sectionTop = section.offsetTop;
			if (scrollY >= sectionTop - 60) {
				current = section.getAttribute("id") || '';
			}
		});

		navItems.forEach((item) => {
			item.classList.remove("active");
			const hrefValue = item.getAttribute('href') || '';
			if (hrefValue.includes(current)) {
				item.classList.add("active");
			}
		});
	};
}

function initMenu() {
	const menuButton = document.querySelector("nav > .navbar__container > .mobile__btn");
	const links = document.querySelector("nav > .navbar__container > .navbar__links");

	menuButton?.addEventListener("click", () => {
		if (this.isMobile()) {
			links?.classList.toggle("mobile");
			document.body.classList.toggle("no-scroll");
		}
	});

	links?.addEventListener("click", () => {
		if (this.isMobile()) {
			links.classList.toggle("mobile");
			document.body.classList.toggle("no-scroll");
		}
	});
}

function isMobile() {
	let isMobile = window.matchMedia("(max-width: 768px)").matches;
	return isMobile;
}